import React from "react";

const Loader1 = () => {
  return (
    <div className="Loader-wrapper">
      <img
        src="/asset/images/loader-image.webp"
        className="img-fluid loader-img"
        width="200"
        height="100"
        alt="loader-image1"
      ></img>
    </div>
  );
};

export default Loader1;
